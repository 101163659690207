<template>
  <div class="home">
    <!-- <button
      v-if="(!startParam || !endParam) && !loading"
      color="warning"
      @click="configure"
    >Configure</button>-->
    <p-radio
      v-for="i in selected"
      :key="i"
      color="info"
      class="p-default p-round p-thick"
      v-model="dialog"
      :value="i"
    >{{ langs[lang][i] }}</p-radio>
  </div>
</template>

<script>
import mixin from "../mixin";
Date.prototype.yyyymmdd = function() {
  var mm = this.getMonth() + 1;
  var dd = this.getDate();
  return [
    this.getFullYear(),
    (mm > 9 ? "" : "0") + mm,
    (dd > 9 ? "" : "0") + dd
  ].join("-");
};

export default {
  name: "home",
  mixins: [mixin],
  data: () => ({
    selected: [],
    lang: "en",
    dialog: "custom",
    start_date: null,
    end_date: null,
    startParam: null,
    endParam: null,
    startValue: null,
    endValue: null,
    loading: true
  }),
  computed: {
    this_year() {
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), 0, 1);
      return [firstDay.yyyymmdd(), date.yyyymmdd()];
    },
    last_year() {
      var date = new Date();
      var firstDay = new Date(date.getFullYear() - 1, 0, 1);
      var lastDay = new Date(date.getFullYear(), 0, 0);
      return [firstDay.yyyymmdd(), lastDay.yyyymmdd()];
    },
    this_quarter() {
      var date = new Date();
      var quarter = Math.floor(date.getMonth() / 3);
      var firstDay = new Date(date.getFullYear(), quarter * 3, 1);
      return [firstDay.yyyymmdd(), date.yyyymmdd()];
    },
    last_quarter() {
      var date = new Date();
      var quarter = Math.floor(date.getMonth() / 3);
      let firstDay, lastDay;
      if (quarter == 0) {
        firstDay = new Date(date.getFullYear() - 1, 9, 1);
        lastDay = new Date(date.getFullYear(), 0, 0);
      } else {
        firstDay = new Date(date.getFullYear(), quarter * 3 - 3, 1);
        lastDay = new Date(date.getFullYear(), quarter * 3, 0);
      }
      return [firstDay.yyyymmdd(), lastDay.yyyymmdd()];
    },
    this_month() {
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      return [firstDay.yyyymmdd(), date.yyyymmdd()];
    },
    last_month() {
      var date = new Date();
      let m = date.getMonth();
      let firstDay, lastDay;
      if (m == 0) {
        firstDay = new Date(date.getFullYear() - 1, 11, 1);
        lastDay = new Date(date.getFullYear(), 0, 0);
      } else {
        firstDay = new Date(date.getFullYear(), m - 1, 1);
        lastDay = new Date(date.getFullYear(), m, 0);
      }
      return [firstDay.yyyymmdd(), lastDay.yyyymmdd()];
    }
  },
  methods: {
    paramChange(parameterChangeEvent) {
      parameterChangeEvent.getParameterAsync().then(param => {
        let name = param.name;
        if (name == this.start_date) {
          this.startValue = param.currentValue._value;
        }
        if (name == this.end_date) {
          this.endValue = param.currentValue._value;
        }
        let has_changed = false;
        let lst = this.selected.filter(c => c !== "custom");
        lst.forEach(t => {
          let [s, e] = this[t];
          if (this.startValue == s && this.endValue == e) {
            this.dialog = t;
            console.log("changed");
            has_changed = true;
          }
        });
        if (!has_changed) {
          this.dialog = "custom";
        }
      });
      console.log("event", this.dialog);
      console.log(this.dialog);
    },
    searchParams(start, end) {
      tableau.extensions.dashboardContent.dashboard
        .getParametersAsync()
        .then(params => {
          for (let param of params) {
            if (param.name === start) {
              this.startParam = param;
              param.addEventListener(
                tableau.TableauEventType.ParameterChanged,
                this.paramChange
              );
              this.startValue = param.currentValue._value;
            }
            if (param.name === end) {
              this.endParam = param;
              param.addEventListener(
                tableau.TableauEventType.ParameterChanged,
                this.paramChange
              );
              this.endValue = param.currentValue._value;
            }
          }
        })
        .then(() => {
          this.apply(this.dialog);
        });
    },
    apply(val) {
      if (val !== "custom" && val) {
        try {
          let start = this[val][0];
          let end = this[val][1];
          let promises = [];
          this.startParam.changeValueAsync(start);
          this.endParam.changeValueAsync(end);
        } catch (e) {
          console.log("APPLY", e);
        }
      }
    },
    configure() {
      const popupUrl = `${window.location.origin}/popup`;
      tableau.extensions.ui
        .displayDialogAsync(popupUrl, "5", { height: 700, width: 500 })
        .then(closePayload => {
          let metadata = JSON.parse(closePayload);
          Object.keys(metadata).forEach(k => {
            this[k] = metadata[k];
            if (k == "selected") {
              metadata[k].push("custom");
              tableau.extensions.settings.set(k, JSON.stringify(metadata[k]));
            } else {
              tableau.extensions.settings.set(k, metadata[k]);
            }
          });

          this.searchParams(metadata["start_date"], metadata["end_date"]);
          tableau.extensions.settings.saveAsync().then(() => {
            console.log("saved");
            this.loading = false;
          });
        })
        .catch(error => {
          this.err = error;
        });
    }
  },
  mounted() {
    tableau.extensions
      .initializeAsync({ configure: this.configure })
      .then(() => {
        console.log("Initialized");
        let start = tableau.extensions.settings.get("start_date");
        let end = tableau.extensions.settings.get("end_date");
        this.searchParams(start, end);
        this.start_date = start;
        this.end_date = end;
        this.dialog = tableau.extensions.settings.get("by_default", "custom");
        this.lang = tableau.extensions.settings.get("lang", "en");
        try {
          let lst = JSON.parse(
            tableau.extensions.settings.get("selected", "[]")
          );
          if (lst.length > 0 && lst.indexOf("custom") == -1) {
            lst.push("custom");
          }
          this.selected = lst;
        } catch (e) {
          console.log("ERROR", e);
        }
        this.loading = false;
      });
  },
  watch: {
    dialog: {
      handler: function(val) {
        this.apply(val);
      },
      immediate: true
    }
  }
};
</script>

<style>
html {
  background: rgba(76, 175, 255, 0);
  overflow-y: hidden;
  padding: 0px;
}
.home {
  margin: 0;
  height: 100vh;
  width: 100vh;
  background: rgba(76, 175, 80, 0);
}
.pretty {
  left: 2px;
  float: left;
  font-size: 11px;
  margin: 2px;
}
.home {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: block;
  max-width: 120px;
  left: 0;
}
</style>
